import { styled } from '@mui/material/styles';
import { useLanguageCtx } from '@src/_new/modules/language';
import { path as translationsPath } from '@src/components/translations/Translations';
import { useAuth } from '@src/hooks';
import Layout from '@src/Layout';
import { SettingsContext } from '@src/modules/setting/SettingsContext';
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
    RouterProvider,
} from 'react-router-dom';

import { LinearLoader } from '../shared/LinearLoader';

const LanguageCreatePage = lazy(() => import('@pages/LanguageCreatePage'));
const LanguageEditPage = lazy(() => import('@pages/LanguageEditPage'));
const LanguageListPage = lazy(() => import('@pages/LanguageListPage'));
const SearchListPage = lazy(() => import('@pages/SearchListPage'));
const SearchDetailPage = lazy(() => import('@pages/SearchDetailPage'));
const ShortListPage = lazy(() => import('@pages/ShortListPage'));
const PrototypesPage = lazy(() => import('@pages/PrototypesPage'));
const ProductsPage = lazy(() => import('@pages/ProductsPage'));
const JobListPage = lazy(() => import('@pages/JobListPage'));
// todo
const Hierarchies = lazy(() => import('@src/modules/hierarchy/HierarchyViewer'));
const Discounts = lazy(() => import('@src/modules/discounts/Discounts'));
const Translations = lazy(() => import('@src/components/translations/Translations'));

const classes = {
    loading: `loading`,
};

const Root = styled('p')(({ theme }) => ({
    [`&.${classes.loading}`]: {
        padding: theme.spacing(1),
    },
}));

function DelayedFallback(): JSX.Element | null {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 150);
        return () => clearTimeout(timeout);
    }, []);

    if (!show) {
        return null;
    }
    return (
        <Root className={classes.loading}>
            <LinearLoader />
        </Root>
    );
}

/**
 * App routes
 * "/language"                          >> LANGUAGE FRAME
 * "/language/new"                      >> NEW LANGUAGE FRAME
 * "/language/:id"                      >> EDIT LANGUAGE id
 * "/products                           >> PRODUCTS WORKFLOW FRAME
 * "/hierarchies/*"                     >> HIERARCHIES FRAME
 * "/translations"                      >> TRANSLATIONS FRAME
 * "/hierarchies/:hierarchyId/:nodeId"  >> EDIT TRANSLATIONS
 */
export function Routes(): JSX.Element {
    const { hasUserRole } = useAuth();
    const { languages } = useLanguageCtx();
    const { enableContentTranslation } = useContext(SettingsContext);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<Layout />}>
                {hasUserRole('PIM_ADMIN') && (
                    <>
                        <Route path="language/new" element={<LanguageCreatePage />} />
                        <Route path="language/:id" element={<LanguageEditPage />} />
                        <Route path="language" element={<LanguageListPage />} />

                        <Route
                            path="products/"
                            index
                            element={languages ? <ProductsPage /> : <Navigate to="/language" />}
                        />
                        <Route
                            path="products/:id"
                            index
                            element={languages ? <ProductsPage /> : <Navigate to="/language" />}
                        />
                        <Route
                            path="products/edit/:id"
                            index
                            element={languages ? <ProductsPage /> : <Navigate to="/language" />}
                        />
                        <Route
                            path="products/:id/:code"
                            index
                            element={languages ? <ProductsPage /> : <Navigate to="/language" />}
                        />
                        <Route
                            path="products/:id/edit/:code"
                            index
                            element={languages ? <ProductsPage /> : <Navigate to="/language" />}
                        />
                        <Route
                            path="products/:id/:code/:typecode"
                            index
                            element={languages ? <ProductsPage /> : <Navigate to="/language" />}
                        />
                    </>
                )}
                {hasUserRole('CGT_PRICELIST') && (
                    <Route
                        path="short_lists"
                        element={
                            languages ? (
                                <ShortListPage />
                            ) : (
                                <Navigate
                                    to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'}
                                />
                            )
                        }
                    />
                )}
                {hasUserRole('CGT_PROTOTYPES') && (
                    <Route
                        path="prototypes/:cgtModelId"
                        element={
                            languages ? (
                                <PrototypesPage />
                            ) : (
                                <Navigate
                                    to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'}
                                />
                            )
                        }
                    />
                )}
                <Route
                    path="hierarchies/*"
                    index
                    element={
                        languages ? (
                            <Hierarchies />
                        ) : (
                            <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'} />
                        )
                    }
                />
                <Route
                    path="search"
                    element={
                        languages ? (
                            <SearchListPage />
                        ) : (
                            <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'} />
                        )
                    }
                />
                <Route
                    path="search/results/:objectSchema"
                    element={
                        languages ? (
                            <SearchDetailPage />
                        ) : (
                            <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'} />
                        )
                    }
                />
                <Route
                    path="discounts"
                    element={
                        languages ? (
                            <Discounts />
                        ) : (
                            <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'} />
                        )
                    }
                />

                <Route
                    path="jobs"
                    element={languages ? <JobListPage /> : <Navigate to="/language" />}
                />

                <Route
                    path="*"
                    element={
                        <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/hierarchies'} />
                    }
                />
                {enableContentTranslation && (
                    <>
                        <Route path={translationsPath} element={<Translations />} />
                        <Route
                            path={`${translationsPath}/:hierarchyId/:nodeId`}
                            element={<Translations />}
                        />
                    </>
                )}
            </Route>,
        ),
    );

    // let span = webTracerWithZone.startSpan(`Router`, contenxt.active());
    // router.subscribe(routeState => {
    //     if (span) {
    //         span.end();
    //     }
    //     span = webTracerWithZone.startSpan(`Router`);
    // });

    return (
        <Suspense fallback={<DelayedFallback />}>
            <RouterProvider router={router} />
        </Suspense>
    );
}

export default Routes;
