import { InitialValue } from '@src/assets/types';
import { createContext } from 'react';

type EditNodeAction =
    | {
          type: 'setEditNodeFormInitialValues';
          payload: {
              initialValues: InitialValue | null;
          };
      }
    | {
          type: 'setNodeInfo';
          payload: {
              hierarchyId: string;
              nodeId: string;
          };
      }
    | {
          type: 'setObjectSchemaId';
          payload: {
              objectSchemaId: string;
          };
      }
    | {
          type: 'toggleSave';
          payload: {
              toggleSave: boolean;
          };
      };

type EditNodeState = null | {
    hierarchyId?: string;
    nodeId?: string;
    initialValues?: InitialValue | null;
    objectSchemaId?: string;
    toggleSave?: boolean;
};

export const editNodeReducer = (state: EditNodeState, action: EditNodeAction): EditNodeState => {
    switch (action.type) {
        case 'setEditNodeFormInitialValues':
            return {
                ...state,
                initialValues: action.payload.initialValues,
            };
        case 'setNodeInfo': {
            return {
                ...state,
                hierarchyId: action.payload.hierarchyId,
                nodeId: action.payload.nodeId,
            };
        }
        case 'setObjectSchemaId': {
            return {
                ...state,
                objectSchemaId: action.payload.objectSchemaId,
            };
        }
        case 'toggleSave': {
            return {
                ...state,
                toggleSave: action.payload.toggleSave,
            };
        }
        default:
            return state;
    }
};

export const EditNodeContext = createContext<{
    state: EditNodeState;
    dispatch: React.Dispatch<EditNodeAction>;
}>({
    state: null,
    dispatch: () => null,
});
