import * as Types from '../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllHierarchiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllHierarchiesQuery = { __typename?: 'Query', hierarchies?: Array<{ __typename?: 'Hierarchy', id: string, code: string, objectSchema?: { __typename?: 'ObjectSchema', id: string, code: string } | null }> | null };

export type GetHierarchyQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  pagination: Types.Pagination;
  sort: Array<Types.Sort> | Types.Sort;
  filter: Array<Types.SearchFilter> | Types.SearchFilter;
  disableNodesTransformation?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetHierarchyQuery = { __typename?: 'Query', searchHierarchies: { __typename?: 'SearchResult', nodes: { __typename?: 'NodeResult', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'SearchNodeEdge', cursor: string, node: { __typename?: 'SearchNode', id: string, code: string, parent: string, parentCode: string, hierarchy: string, hierarchyCode: string, disabled: boolean, writeRoles?: Array<string> | null, contentTypeGroupStatus?: Array<{ __typename?: 'ContentTypeGroupStatus', code: string, status: Types.CtgStatus }> | null, contents: Array<{ __typename?: 'NodeContentO', contentType: string, contentTypeGroup: string, type: string, inherited: boolean, value?: Array<{ __typename?: 'NodeContentValueO', code?: string | null, value: Array<any | null>, languageIsoCode: string, measure?: string | null }> | null }>, metadata?: { __typename?: 'Metadata', createdAt?: any | null, updatedAt?: any | null } | null } }> } } };

export type GetHierarchyNodesBaseInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  filter: Array<Types.SearchFilter> | Types.SearchFilter;
  sort: Array<Types.Sort> | Types.Sort;
}>;


export type GetHierarchyNodesBaseInfoQuery = { __typename?: 'Query', searchHierarchies: { __typename?: 'SearchResult', nodes: { __typename?: 'NodeResult', edges: Array<{ __typename?: 'SearchNodeEdge', node: { __typename?: 'SearchNode', id: string, code: string } }> } } };

export type ExportXlsxqueryMutationVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  sort: Array<Types.Sort> | Types.Sort;
  filter: Array<Types.SearchFilter> | Types.SearchFilter;
  nodeId?: Types.InputMaybe<Types.Scalars['String']>;
  visibleFields?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type ExportXlsxqueryMutation = { __typename?: 'Mutation', exportXlsx?: string | null };


export const GetAllHierarchiesDocument = gql`
    query GetAllHierarchies {
  hierarchies {
    id
    code
    objectSchema {
      id
      code
    }
  }
}
    `;

/**
 * __useGetAllHierarchiesQuery__
 *
 * To run a query within a React component, call `useGetAllHierarchiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllHierarchiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllHierarchiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllHierarchiesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllHierarchiesQuery, GetAllHierarchiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllHierarchiesQuery, GetAllHierarchiesQueryVariables>(GetAllHierarchiesDocument, options);
      }
export function useGetAllHierarchiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllHierarchiesQuery, GetAllHierarchiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllHierarchiesQuery, GetAllHierarchiesQueryVariables>(GetAllHierarchiesDocument, options);
        }
export type GetAllHierarchiesQueryHookResult = ReturnType<typeof useGetAllHierarchiesQuery>;
export type GetAllHierarchiesLazyQueryHookResult = ReturnType<typeof useGetAllHierarchiesLazyQuery>;
export type GetAllHierarchiesQueryResult = Apollo.QueryResult<GetAllHierarchiesQuery, GetAllHierarchiesQueryVariables>;
export const GetHierarchyDocument = gql`
    query GetHierarchy($ids: [String!], $pagination: Pagination!, $sort: [Sort!]!, $filter: [SearchFilter!]!, $disableNodesTransformation: Boolean) {
  searchHierarchies(
    ids: $ids
    pagination: $pagination
    sort: $sort
    filter: $filter
    disableNodesTransformation: $disableNodesTransformation
  ) {
    nodes {
      count
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          code
          parent
          parentCode
          hierarchy
          hierarchyCode
          disabled
          contentTypeGroupStatus {
            code
            status
          }
          contents {
            contentType
            contentTypeGroup
            type
            inherited
            value {
              code
              value
              languageIsoCode
              measure
            }
          }
          writeRoles
          metadata {
            createdAt
            updatedAt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetHierarchyQuery__
 *
 * To run a query within a React component, call `useGetHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHierarchyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      disableNodesTransformation: // value for 'disableNodesTransformation'
 *   },
 * });
 */
export function useGetHierarchyQuery(baseOptions: Apollo.QueryHookOptions<GetHierarchyQuery, GetHierarchyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHierarchyQuery, GetHierarchyQueryVariables>(GetHierarchyDocument, options);
      }
export function useGetHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHierarchyQuery, GetHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHierarchyQuery, GetHierarchyQueryVariables>(GetHierarchyDocument, options);
        }
export type GetHierarchyQueryHookResult = ReturnType<typeof useGetHierarchyQuery>;
export type GetHierarchyLazyQueryHookResult = ReturnType<typeof useGetHierarchyLazyQuery>;
export type GetHierarchyQueryResult = Apollo.QueryResult<GetHierarchyQuery, GetHierarchyQueryVariables>;
export const GetHierarchyNodesBaseInfoDocument = gql`
    query GetHierarchyNodesBaseInfo($id: String!, $filter: [SearchFilter!]!, $sort: [Sort!]!) {
  searchHierarchies(
    ids: [$id]
    filter: $filter
    pagination: {first: 50}
    sort: $sort
  ) {
    nodes {
      edges {
        node {
          id
          code
        }
      }
    }
  }
}
    `;

/**
 * __useGetHierarchyNodesBaseInfoQuery__
 *
 * To run a query within a React component, call `useGetHierarchyNodesBaseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHierarchyNodesBaseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHierarchyNodesBaseInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetHierarchyNodesBaseInfoQuery(baseOptions: Apollo.QueryHookOptions<GetHierarchyNodesBaseInfoQuery, GetHierarchyNodesBaseInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHierarchyNodesBaseInfoQuery, GetHierarchyNodesBaseInfoQueryVariables>(GetHierarchyNodesBaseInfoDocument, options);
      }
export function useGetHierarchyNodesBaseInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHierarchyNodesBaseInfoQuery, GetHierarchyNodesBaseInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHierarchyNodesBaseInfoQuery, GetHierarchyNodesBaseInfoQueryVariables>(GetHierarchyNodesBaseInfoDocument, options);
        }
export type GetHierarchyNodesBaseInfoQueryHookResult = ReturnType<typeof useGetHierarchyNodesBaseInfoQuery>;
export type GetHierarchyNodesBaseInfoLazyQueryHookResult = ReturnType<typeof useGetHierarchyNodesBaseInfoLazyQuery>;
export type GetHierarchyNodesBaseInfoQueryResult = Apollo.QueryResult<GetHierarchyNodesBaseInfoQuery, GetHierarchyNodesBaseInfoQueryVariables>;
export const ExportXlsxqueryDocument = gql`
    mutation ExportXlsxquery($ids: [String!], $sort: [Sort!]!, $filter: [SearchFilter!]!, $nodeId: String, $visibleFields: [String!]) {
  exportXlsx(
    ids: $ids
    sort: $sort
    filter: $filter
    nodeId: $nodeId
    visibleFields: $visibleFields
  )
}
    `;
export type ExportXlsxqueryMutationFn = Apollo.MutationFunction<ExportXlsxqueryMutation, ExportXlsxqueryMutationVariables>;

/**
 * __useExportXlsxqueryMutation__
 *
 * To run a mutation, you first call `useExportXlsxqueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportXlsxqueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportXlsxqueryMutation, { data, loading, error }] = useExportXlsxqueryMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      nodeId: // value for 'nodeId'
 *      visibleFields: // value for 'visibleFields'
 *   },
 * });
 */
export function useExportXlsxqueryMutation(baseOptions?: Apollo.MutationHookOptions<ExportXlsxqueryMutation, ExportXlsxqueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportXlsxqueryMutation, ExportXlsxqueryMutationVariables>(ExportXlsxqueryDocument, options);
      }
export type ExportXlsxqueryMutationHookResult = ReturnType<typeof useExportXlsxqueryMutation>;
export type ExportXlsxqueryMutationResult = Apollo.MutationResult<ExportXlsxqueryMutation>;
export type ExportXlsxqueryMutationOptions = Apollo.BaseMutationOptions<ExportXlsxqueryMutation, ExportXlsxqueryMutationVariables>;