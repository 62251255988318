import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrototypeRootQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PrototypeRootQuery = { __typename?: 'Query', prototypeRoot?: { __typename?: 'Hierarchy', id: string } | null };

export type PrototypeCountQueryVariables = Types.Exact<{
  modelName: Types.Scalars['String'];
}>;


export type PrototypeCountQuery = { __typename?: 'Query', prototypeCount: number };

export type GetGlCharacteristicsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGlCharacteristicsQuery = { __typename?: 'Query', glCharacteristics: Array<{ __typename?: 'GlCharacteristic', label: string, code: string, options: Array<{ __typename?: 'GlCharacteristicOption', label: string, value: string }> }> };

export type GetPrototypeCertificationsQueryVariables = Types.Exact<{
  prototypeId: Types.Scalars['String'];
}>;


export type GetPrototypeCertificationsQuery = { __typename?: 'Query', getPrototypeCertifications: Array<{ __typename?: 'PrototypeCertifications', id: string, date: string, user: string }> };

export type GetPrototypesQueryVariables = Types.Exact<{
  modelName: Types.Scalars['String'];
}>;


export type GetPrototypesQuery = { __typename?: 'Query', getPrototypes: Array<{ __typename?: 'HierarchyNode', id: string, code: string, parent: string, certificationStatus?: string | null, linkedBy?: Array<{ __typename?: 'LinkedBy', nodeId: string } | null> | null, contentTypeGroupStatus?: Array<{ __typename?: 'ContentTypeGroupStatus', code: string, status: Types.CtgStatus, publishedAt?: string | null }> | null, contents?: Array<{ __typename?: 'NodeContentO', contentTypeGroup: string, contentTypeGroupIndex: number, contentType: string, repeatable?: boolean | null, type: string, inherited: boolean, value?: Array<{ __typename?: 'NodeContentValueO', languageIsoCode: string, value: Array<any | null>, code?: string | null, measure?: string | null }> | null }> | null }> };

export type GetModelGlsStatusesQueryVariables = Types.Exact<{
  modelId: Types.Scalars['String'];
}>;


export type GetModelGlsStatusesQuery = { __typename?: 'Query', modelGlsStatuses: Array<{ __typename?: 'ModelGlStatuses', glId: string, statuses: Array<{ __typename?: 'ModelGlStatusData', status: Types.ModelGlStatus, data?: Array<string> | null }> }> };

export type CreatePrototypeMutationVariables = Types.Exact<{
  modelName: Types.Scalars['String'];
  prototypeCode: Types.Scalars['String'];
}>;


export type CreatePrototypeMutation = { __typename?: 'Mutation', createPrototype?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type CreateLongListMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  commercialFamilyId: Types.Scalars['String'];
  commercialFamilyParentId: Types.Scalars['String'];
  prototypeId: Types.Scalars['String'];
}>;


export type CreateLongListMutation = { __typename?: 'Mutation', createLongList?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type DuplicatePrototypeMutationVariables = Types.Exact<{
  modelName: Types.Scalars['String'];
  prototypeId: Types.Scalars['String'];
  prototypeCode: Types.Scalars['String'];
  contents?: Types.InputMaybe<Array<Types.NodeContent> | Types.NodeContent>;
}>;


export type DuplicatePrototypeMutation = { __typename?: 'Mutation', duplicatePrototype?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type PublishLongListMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  node: Types.Scalars['String'];
  cgtModelVariables: Types.CgtModelVariables;
  prototypeId: Types.Scalars['String'];
}>;


export type PublishLongListMutation = { __typename?: 'Mutation', publishLongList: { __typename?: 'PublishResponse', success: boolean } };

export type ChangeFamilyMutationVariables = Types.Exact<{
  longListParent: Types.Scalars['String'];
  longListId: Types.Scalars['String'];
  familyId: Types.Scalars['String'];
}>;


export type ChangeFamilyMutation = { __typename?: 'Mutation', changeLongListCommercialFamily?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };


export const PrototypeRootDocument = gql`
    query PrototypeRoot {
  prototypeRoot {
    id
  }
}
    `;

/**
 * __usePrototypeRootQuery__
 *
 * To run a query within a React component, call `usePrototypeRootQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrototypeRootQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrototypeRootQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrototypeRootQuery(baseOptions?: Apollo.QueryHookOptions<PrototypeRootQuery, PrototypeRootQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrototypeRootQuery, PrototypeRootQueryVariables>(PrototypeRootDocument, options);
      }
export function usePrototypeRootLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrototypeRootQuery, PrototypeRootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrototypeRootQuery, PrototypeRootQueryVariables>(PrototypeRootDocument, options);
        }
export type PrototypeRootQueryHookResult = ReturnType<typeof usePrototypeRootQuery>;
export type PrototypeRootLazyQueryHookResult = ReturnType<typeof usePrototypeRootLazyQuery>;
export type PrototypeRootQueryResult = Apollo.QueryResult<PrototypeRootQuery, PrototypeRootQueryVariables>;
export const PrototypeCountDocument = gql`
    query PrototypeCount($modelName: String!) {
  prototypeCount(modelName: $modelName)
}
    `;

/**
 * __usePrototypeCountQuery__
 *
 * To run a query within a React component, call `usePrototypeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrototypeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrototypeCountQuery({
 *   variables: {
 *      modelName: // value for 'modelName'
 *   },
 * });
 */
export function usePrototypeCountQuery(baseOptions: Apollo.QueryHookOptions<PrototypeCountQuery, PrototypeCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrototypeCountQuery, PrototypeCountQueryVariables>(PrototypeCountDocument, options);
      }
export function usePrototypeCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrototypeCountQuery, PrototypeCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrototypeCountQuery, PrototypeCountQueryVariables>(PrototypeCountDocument, options);
        }
export type PrototypeCountQueryHookResult = ReturnType<typeof usePrototypeCountQuery>;
export type PrototypeCountLazyQueryHookResult = ReturnType<typeof usePrototypeCountLazyQuery>;
export type PrototypeCountQueryResult = Apollo.QueryResult<PrototypeCountQuery, PrototypeCountQueryVariables>;
export const GetGlCharacteristicsDocument = gql`
    query GetGlCharacteristics {
  glCharacteristics {
    label
    code
    options {
      label
      value
    }
  }
}
    `;

/**
 * __useGetGlCharacteristicsQuery__
 *
 * To run a query within a React component, call `useGetGlCharacteristicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlCharacteristicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlCharacteristicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlCharacteristicsQuery(baseOptions?: Apollo.QueryHookOptions<GetGlCharacteristicsQuery, GetGlCharacteristicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlCharacteristicsQuery, GetGlCharacteristicsQueryVariables>(GetGlCharacteristicsDocument, options);
      }
export function useGetGlCharacteristicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlCharacteristicsQuery, GetGlCharacteristicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlCharacteristicsQuery, GetGlCharacteristicsQueryVariables>(GetGlCharacteristicsDocument, options);
        }
export type GetGlCharacteristicsQueryHookResult = ReturnType<typeof useGetGlCharacteristicsQuery>;
export type GetGlCharacteristicsLazyQueryHookResult = ReturnType<typeof useGetGlCharacteristicsLazyQuery>;
export type GetGlCharacteristicsQueryResult = Apollo.QueryResult<GetGlCharacteristicsQuery, GetGlCharacteristicsQueryVariables>;
export const GetPrototypeCertificationsDocument = gql`
    query GetPrototypeCertifications($prototypeId: String!) {
  getPrototypeCertifications(prototypeId: $prototypeId) {
    id
    date
    user
  }
}
    `;

/**
 * __useGetPrototypeCertificationsQuery__
 *
 * To run a query within a React component, call `useGetPrototypeCertificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrototypeCertificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrototypeCertificationsQuery({
 *   variables: {
 *      prototypeId: // value for 'prototypeId'
 *   },
 * });
 */
export function useGetPrototypeCertificationsQuery(baseOptions: Apollo.QueryHookOptions<GetPrototypeCertificationsQuery, GetPrototypeCertificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrototypeCertificationsQuery, GetPrototypeCertificationsQueryVariables>(GetPrototypeCertificationsDocument, options);
      }
export function useGetPrototypeCertificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrototypeCertificationsQuery, GetPrototypeCertificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrototypeCertificationsQuery, GetPrototypeCertificationsQueryVariables>(GetPrototypeCertificationsDocument, options);
        }
export type GetPrototypeCertificationsQueryHookResult = ReturnType<typeof useGetPrototypeCertificationsQuery>;
export type GetPrototypeCertificationsLazyQueryHookResult = ReturnType<typeof useGetPrototypeCertificationsLazyQuery>;
export type GetPrototypeCertificationsQueryResult = Apollo.QueryResult<GetPrototypeCertificationsQuery, GetPrototypeCertificationsQueryVariables>;
export const GetPrototypesDocument = gql`
    query GetPrototypes($modelName: String!) {
  getPrototypes(modelName: $modelName) {
    id
    code
    parent
    linkedBy {
      nodeId
    }
    contentTypeGroupStatus {
      code
      status
      publishedAt
    }
    certificationStatus
    contents {
      contentTypeGroup
      contentTypeGroupIndex
      contentType
      repeatable
      type
      inherited
      value {
        languageIsoCode
        value
        code
        measure
      }
    }
  }
}
    `;

/**
 * __useGetPrototypesQuery__
 *
 * To run a query within a React component, call `useGetPrototypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrototypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrototypesQuery({
 *   variables: {
 *      modelName: // value for 'modelName'
 *   },
 * });
 */
export function useGetPrototypesQuery(baseOptions: Apollo.QueryHookOptions<GetPrototypesQuery, GetPrototypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrototypesQuery, GetPrototypesQueryVariables>(GetPrototypesDocument, options);
      }
export function useGetPrototypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrototypesQuery, GetPrototypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrototypesQuery, GetPrototypesQueryVariables>(GetPrototypesDocument, options);
        }
export type GetPrototypesQueryHookResult = ReturnType<typeof useGetPrototypesQuery>;
export type GetPrototypesLazyQueryHookResult = ReturnType<typeof useGetPrototypesLazyQuery>;
export type GetPrototypesQueryResult = Apollo.QueryResult<GetPrototypesQuery, GetPrototypesQueryVariables>;
export const GetModelGlsStatusesDocument = gql`
    query GetModelGlsStatuses($modelId: String!) {
  modelGlsStatuses(modelId: $modelId) {
    glId
    statuses {
      status
      data
    }
  }
}
    `;

/**
 * __useGetModelGlsStatusesQuery__
 *
 * To run a query within a React component, call `useGetModelGlsStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelGlsStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelGlsStatusesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetModelGlsStatusesQuery(baseOptions: Apollo.QueryHookOptions<GetModelGlsStatusesQuery, GetModelGlsStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelGlsStatusesQuery, GetModelGlsStatusesQueryVariables>(GetModelGlsStatusesDocument, options);
      }
export function useGetModelGlsStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelGlsStatusesQuery, GetModelGlsStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelGlsStatusesQuery, GetModelGlsStatusesQueryVariables>(GetModelGlsStatusesDocument, options);
        }
export type GetModelGlsStatusesQueryHookResult = ReturnType<typeof useGetModelGlsStatusesQuery>;
export type GetModelGlsStatusesLazyQueryHookResult = ReturnType<typeof useGetModelGlsStatusesLazyQuery>;
export type GetModelGlsStatusesQueryResult = Apollo.QueryResult<GetModelGlsStatusesQuery, GetModelGlsStatusesQueryVariables>;
export const CreatePrototypeDocument = gql`
    mutation CreatePrototype($modelName: String!, $prototypeCode: String!) {
  createPrototype(modelName: $modelName, prototypeCode: $prototypeCode) {
    code
    success
    message
  }
}
    `;
export type CreatePrototypeMutationFn = Apollo.MutationFunction<CreatePrototypeMutation, CreatePrototypeMutationVariables>;

/**
 * __useCreatePrototypeMutation__
 *
 * To run a mutation, you first call `useCreatePrototypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrototypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrototypeMutation, { data, loading, error }] = useCreatePrototypeMutation({
 *   variables: {
 *      modelName: // value for 'modelName'
 *      prototypeCode: // value for 'prototypeCode'
 *   },
 * });
 */
export function useCreatePrototypeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrototypeMutation, CreatePrototypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrototypeMutation, CreatePrototypeMutationVariables>(CreatePrototypeDocument, options);
      }
export type CreatePrototypeMutationHookResult = ReturnType<typeof useCreatePrototypeMutation>;
export type CreatePrototypeMutationResult = Apollo.MutationResult<CreatePrototypeMutation>;
export type CreatePrototypeMutationOptions = Apollo.BaseMutationOptions<CreatePrototypeMutation, CreatePrototypeMutationVariables>;
export const CreateLongListDocument = gql`
    mutation CreateLongList($aggregateId: String!, $commercialFamilyId: String!, $commercialFamilyParentId: String!, $prototypeId: String!) {
  createLongList(
    aggregateId: $aggregateId
    commercialFamilyId: $commercialFamilyId
    commercialFamilyParentId: $commercialFamilyParentId
    prototypeId: $prototypeId
  ) {
    code
    success
    message
  }
}
    `;
export type CreateLongListMutationFn = Apollo.MutationFunction<CreateLongListMutation, CreateLongListMutationVariables>;

/**
 * __useCreateLongListMutation__
 *
 * To run a mutation, you first call `useCreateLongListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLongListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLongListMutation, { data, loading, error }] = useCreateLongListMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      commercialFamilyId: // value for 'commercialFamilyId'
 *      commercialFamilyParentId: // value for 'commercialFamilyParentId'
 *      prototypeId: // value for 'prototypeId'
 *   },
 * });
 */
export function useCreateLongListMutation(baseOptions?: Apollo.MutationHookOptions<CreateLongListMutation, CreateLongListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLongListMutation, CreateLongListMutationVariables>(CreateLongListDocument, options);
      }
export type CreateLongListMutationHookResult = ReturnType<typeof useCreateLongListMutation>;
export type CreateLongListMutationResult = Apollo.MutationResult<CreateLongListMutation>;
export type CreateLongListMutationOptions = Apollo.BaseMutationOptions<CreateLongListMutation, CreateLongListMutationVariables>;
export const DuplicatePrototypeDocument = gql`
    mutation DuplicatePrototype($modelName: String!, $prototypeId: String!, $prototypeCode: String!, $contents: [NodeContent!]) {
  duplicatePrototype(
    modelName: $modelName
    prototypeId: $prototypeId
    prototypeCode: $prototypeCode
    contents: $contents
  ) {
    code
    success
    message
  }
}
    `;
export type DuplicatePrototypeMutationFn = Apollo.MutationFunction<DuplicatePrototypeMutation, DuplicatePrototypeMutationVariables>;

/**
 * __useDuplicatePrototypeMutation__
 *
 * To run a mutation, you first call `useDuplicatePrototypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePrototypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePrototypeMutation, { data, loading, error }] = useDuplicatePrototypeMutation({
 *   variables: {
 *      modelName: // value for 'modelName'
 *      prototypeId: // value for 'prototypeId'
 *      prototypeCode: // value for 'prototypeCode'
 *      contents: // value for 'contents'
 *   },
 * });
 */
export function useDuplicatePrototypeMutation(baseOptions?: Apollo.MutationHookOptions<DuplicatePrototypeMutation, DuplicatePrototypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicatePrototypeMutation, DuplicatePrototypeMutationVariables>(DuplicatePrototypeDocument, options);
      }
export type DuplicatePrototypeMutationHookResult = ReturnType<typeof useDuplicatePrototypeMutation>;
export type DuplicatePrototypeMutationResult = Apollo.MutationResult<DuplicatePrototypeMutation>;
export type DuplicatePrototypeMutationOptions = Apollo.BaseMutationOptions<DuplicatePrototypeMutation, DuplicatePrototypeMutationVariables>;
export const PublishLongListDocument = gql`
    mutation PublishLongList($aggregateId: String!, $node: String!, $cgtModelVariables: CgtModelVariables!, $prototypeId: String!) {
  publishLongList(
    aggregateId: $aggregateId
    node: $node
    cgtModelVariables: $cgtModelVariables
    prototypeId: $prototypeId
  ) {
    success
  }
}
    `;
export type PublishLongListMutationFn = Apollo.MutationFunction<PublishLongListMutation, PublishLongListMutationVariables>;

/**
 * __usePublishLongListMutation__
 *
 * To run a mutation, you first call `usePublishLongListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishLongListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishLongListMutation, { data, loading, error }] = usePublishLongListMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      node: // value for 'node'
 *      cgtModelVariables: // value for 'cgtModelVariables'
 *      prototypeId: // value for 'prototypeId'
 *   },
 * });
 */
export function usePublishLongListMutation(baseOptions?: Apollo.MutationHookOptions<PublishLongListMutation, PublishLongListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishLongListMutation, PublishLongListMutationVariables>(PublishLongListDocument, options);
      }
export type PublishLongListMutationHookResult = ReturnType<typeof usePublishLongListMutation>;
export type PublishLongListMutationResult = Apollo.MutationResult<PublishLongListMutation>;
export type PublishLongListMutationOptions = Apollo.BaseMutationOptions<PublishLongListMutation, PublishLongListMutationVariables>;
export const ChangeFamilyDocument = gql`
    mutation ChangeFamily($longListParent: String!, $longListId: String!, $familyId: String!) {
  changeLongListCommercialFamily(
    longListParent: $longListParent
    longListId: $longListId
    familyId: $familyId
  ) {
    code
    success
    message
  }
}
    `;
export type ChangeFamilyMutationFn = Apollo.MutationFunction<ChangeFamilyMutation, ChangeFamilyMutationVariables>;

/**
 * __useChangeFamilyMutation__
 *
 * To run a mutation, you first call `useChangeFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFamilyMutation, { data, loading, error }] = useChangeFamilyMutation({
 *   variables: {
 *      longListParent: // value for 'longListParent'
 *      longListId: // value for 'longListId'
 *      familyId: // value for 'familyId'
 *   },
 * });
 */
export function useChangeFamilyMutation(baseOptions?: Apollo.MutationHookOptions<ChangeFamilyMutation, ChangeFamilyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeFamilyMutation, ChangeFamilyMutationVariables>(ChangeFamilyDocument, options);
      }
export type ChangeFamilyMutationHookResult = ReturnType<typeof useChangeFamilyMutation>;
export type ChangeFamilyMutationResult = Apollo.MutationResult<ChangeFamilyMutation>;
export type ChangeFamilyMutationOptions = Apollo.BaseMutationOptions<ChangeFamilyMutation, ChangeFamilyMutationVariables>;