import {
    Backdrop,
    Box,
    CircularProgress,
    EditIcon,
    FormatListBulletedIcon,
    FormControl,
    FormControlLabel,
    IconButton,
    SearchIcon,
    Stack,
    StackProps,
    Switch,
    TextField,
} from '@ds/coolshop';
import {
    ChangeEventHandler,
    createContext,
    forwardRef,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useContext,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

const SidebarContext = createContext({ loading: false });

const useSidebarContext = () => {
    const context = useContext(SidebarContext);

    if (!context)
        throw new Error(
            'You are using a <PimSideBar... /> component outside the <PimSideBar /> provider',
        );

    return context;
};

type PimSideBarProps = {
    loading?: boolean;
    fullWidth?: boolean;
    topComponent: ReactNode;
    bottomComponent: ReactNode;
};

export const PimSideBar = ({
    loading,
    fullWidth,
    topComponent,
    bottomComponent,
}: PimSideBarProps) => {
    const sidebarContextValue = useMemo(() => ({ loading: loading ?? false }), [loading]);
    return (
        <SidebarContext.Provider value={sidebarContextValue}>
            <Box
                height="100%"
                minWidth={300}
                width={fullWidth ? '100%' : '25%'}
                position="relative"
            >
                <Backdrop open={Boolean(loading)} position="absolute" zIndex={10}>
                    <CircularProgress />
                </Backdrop>
                <Stack height="100%" gap={1}>
                    <Stack gap={2} paddingInline={3} paddingBlock={2}>
                        {topComponent}
                    </Stack>
                    <Box overflowY="auto" overflowX="hidden">
                        <Box>{bottomComponent}</Box>
                    </Box>
                </Stack>
            </Box>
        </SidebarContext.Provider>
    );
};

type PimSideBarSearchProps = {
    value: string | undefined;
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export const PimSideBarSearch = ({ value, onChange }: PimSideBarSearchProps) => {
    const intl = useIntl();

    const { loading } = useSidebarContext();

    return (
        <FormControl fullWidth>
            <TextField
                placeholder={intl.formatMessage({
                    id: 'search',
                    defaultMessage: 'Search',
                })}
                onChange={e => {
                    if (loading) return;
                    onChange(e);
                }}
                StartIcon={SearchIcon}
                value={value}
            />
        </FormControl>
    );
};

type PimSideBarElementProps = {
    selected?: boolean;
    stackProps?: StackProps;
    onClick?: () => void;
};

export const PimSideBarElement = forwardRef<
    HTMLDivElement,
    Required<PropsWithChildren> & PimSideBarElementProps
>(({ selected, stackProps, onClick, children }, ref) => (
    <Stack
        ref={ref}
        paddingBlock={1}
        paddingInline={3}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={onClick}
        cursor="pointer"
        {...(selected ? { bgcolor: t => t.palette.grey[300] } : {})}
        {...stackProps}
    >
        {children}
    </Stack>
));

type PimSideBarActionType = 'edit' | 'children' | 'custom';

type PimSideBarEditAction = {
    type: Extract<PimSideBarActionType, 'edit'>;
};

type PimSideBarChildrenAction = {
    type: Extract<PimSideBarActionType, 'children'>;
};

type PimSideBarCustomAction = {
    type: Extract<PimSideBarActionType, 'custom'>;
    element: ReactElement;
};

type PimSideBarStandardAction = PimSideBarEditAction | PimSideBarChildrenAction;

type PimSideBarAction = {
    show?: boolean;
    onClick?: () => void;
} & (PimSideBarStandardAction | PimSideBarCustomAction);

type PimSideBarElementActionsProps = {
    actions: PimSideBarAction[];
};

export const PimSideBarElementActions = ({ actions }: PimSideBarElementActionsProps) => {
    const visibleActions = actions.filter(action => action.show);

    return (
        <Stack direction="row" alignItems="center" gap={0.5}>
            {visibleActions.map(action => {
                if (action.type === 'edit')
                    return (
                        <IconButton
                            key={action.type}
                            aria-label="edit"
                            size="small"
                            color="primary"
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    );

                if (action.type === 'children')
                    return (
                        <IconButton
                            key={action.type}
                            aria-label="show children"
                            size="small"
                            onClick={e => {
                                e.stopPropagation();
                                action.onClick?.();
                            }}
                            color="primary"
                        >
                            <FormatListBulletedIcon fontSize="small" />
                        </IconButton>
                    );

                return action.element;
            })}
        </Stack>
    );
};

export const PimSideBarDisabledFilter = ({
    onChange,
}: {
    onChange: (checked: boolean) => void;
}) => {
    const intl = useIntl();

    const [checked, setChecked] = useState(true);

    const { loading } = useSidebarContext();

    const handleChange = () => {
        setChecked(!checked);
        onChange(!checked);
    };

    return (
        <FormControlLabel
            disabled={loading}
            typography="caption"
            checked={checked}
            onChange={handleChange}
            label={intl.formatMessage({
                id: 'hide-disabled.toggle',
                defaultMessage: 'Hide disabled',
            })}
            control={<Switch size="small" />}
        />
    );
};
