import { useExportJobFinishedSubscription } from '@src/modules/hierarchy/hierarchyQuery.generated';
import { downloadFile } from '@src/utils/hierarchies/downloadFile';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import JobSubscriptionContext from './JobSubscriptionContext';

const JobSubscriptionContextProvider = ({ children }: { children: ReactNode }) => {
    const [jobId, setJobId] = useState<string | null>(null);

    const { data } = useExportJobFinishedSubscription({
        shouldResubscribe: true,
        variables: {
            jobId,
        },
        skip: !jobId,
    });

    const contextValue = useMemo(
        () => ({
            setJobId,
        }),
        [setJobId],
    );

    useEffect(() => {
        if (data?.exportJobFinished) {
            const { filename, type } = data.exportJobFinished;
            if (filename && type) {
                downloadFile(filename, type);
            }
        }
    }, [data]);

    return (
        <JobSubscriptionContext.Provider value={contextValue}>
            {children}
        </JobSubscriptionContext.Provider>
    );
};

export default JobSubscriptionContextProvider;
