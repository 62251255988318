export async function downloadFile(fileName: string, type: string, downloadName?: string) {
    fetch(`/api/download/${type}/${fileName}`)
        .then(resp => {
            if (resp.ok) {
                const contentFilename = getFilename(resp.headers.get('Content-Disposition'));
                return {
                    blobPromise: resp.blob(),
                    contentFilename,
                };
            }
            throw new Error('Invalid response');
        })
        .then(async ({ blobPromise, contentFilename }) => {
            const blob = await blobPromise;

            const fileLink = document.createElement('a');
            fileLink.href = window.URL.createObjectURL(blob);
            fileLink.download = downloadName ?? contentFilename ?? fileName;
            fileLink.click();
        });
}

function getFilename(disposition: string | null): string | null {
    if (typeof disposition !== 'string') {
        return null;
    }

    if (disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
    }

    return null;
}
