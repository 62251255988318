import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, useLocation } from 'react-router-dom';

import { PimAppBar } from './components/layout/PimAppBar';

const Layout = () => {
    const { pathname } = useLocation();
    const isCGTPrototypesPage = pathname.split('/')[1] === 'prototypes';

    return (
        <Box>
            <CssBaseline />
            {!isCGTPrototypesPage && <PimAppBar />}
            <Box
                sx={
                    !isCGTPrototypesPage
                        ? {
                              flexGrow: 1,
                              height: 'calc(100vh - 64px)',
                              overflow: 'auto',
                              marginTop: '64px', // TODO this is the height of the appBar
                          }
                        : {}
                }
            >
                <Outlet />
            </Box>
        </Box>
    );
};

export default Layout;
