import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckGlItemsQueryVariables = Types.Exact<{
  modelId: Types.Scalars['String'];
  itemValues: Array<Types.InputMaybe<Types.Scalars['NodeValue']>> | Types.InputMaybe<Types.Scalars['NodeValue']>;
  glType: Types.Scalars['String'];
  glNodeId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CheckGlItemsQuery = { __typename?: 'Query', glItems: { __typename?: 'CheckResponse', message: string, success: boolean, codes?: Array<string> | null } };

export type CheckInvalidGlsQueryVariables = Types.Exact<{
  modelId: Types.Scalars['String'];
  priceListId: Types.Scalars['String'];
}>;


export type CheckInvalidGlsQuery = { __typename?: 'Query', invalidGls: Array<{ __typename?: 'HierarchyNode', id: string, code: string } | null> };

export type CheckGlMismatchQueryVariables = Types.Exact<{
  priceListId: Types.Scalars['String'];
}>;


export type CheckGlMismatchQuery = { __typename?: 'Query', glMismatch: Array<string> };

export type PricesQueryVariables = Types.Exact<{
  hierarchyId: Types.Scalars['String'];
  contentTypeGroupCode: Types.Scalars['String'];
}>;


export type PricesQuery = { __typename?: 'Query', prices: Array<{ __typename?: 'Price', key: string, value?: string | null }> };

export type GetNodeIdByCodeQueryVariables = Types.Exact<{
  hierarchyId: Types.Scalars['String'];
  nodeCode: Types.Scalars['String'];
}>;


export type GetNodeIdByCodeQuery = { __typename?: 'Query', getNodeIdByCode?: string | null };

export type CertificatePrototypeMutationVariables = Types.Exact<{
  prototypeId: Types.Scalars['String'];
  cgtModelVariables: Types.CgtModelVariables;
}>;


export type CertificatePrototypeMutation = { __typename?: 'Mutation', certificatePrototype?: { __typename?: 'CertificationResponse', documentId: string } | null };

export type CertificateModelMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  nodeId: Types.Scalars['String'];
}>;


export type CertificateModelMutation = { __typename?: 'Mutation', certificateModel?: { __typename?: 'CertificationResponse', documentId: string, salesforceDocumentId?: string | null } | null };

export type PublishModelMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  nodeId: Types.Scalars['String'];
}>;


export type PublishModelMutation = { __typename?: 'Mutation', publishModel?: { __typename?: 'CertificationResponse', documentId: string } | null };

export type AlignLongListsMutationVariables = Types.Exact<{
  prototypeId: Types.Scalars['String'];
}>;


export type AlignLongListsMutation = { __typename?: 'Mutation', alignLongLists?: { __typename?: 'ObjectSchemaMutationResponse', code: string, message: string, success: boolean } | null };

export type AlignMismatchGlMutationVariables = Types.Exact<{
  modelId: Types.Scalars['String'];
  itemsCodes: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type AlignMismatchGlMutation = { __typename?: 'Mutation', alignMismatchGl?: { __typename?: 'ObjectSchemaMutationResponse', code: string, message: string, success: boolean } | null };

export type AlignLonglistsSectionsMutationVariables = Types.Exact<{
  sectionNodeId: Types.Scalars['String'];
  name: Types.Scalars['String'];
  description: Types.Scalars['String'];
  notes: Types.Scalars['String'];
}>;


export type AlignLonglistsSectionsMutation = { __typename?: 'Mutation', alignLonglistsSections?: { __typename?: 'ObjectSchemaMutationResponse', code: string, message: string, success: boolean } | null };

export type ReplaceItemMutationVariables = Types.Exact<{
  nodeId: Types.Scalars['String'];
  nodeCode: Types.Scalars['String'];
  newItemCode: Types.Scalars['String'];
}>;


export type ReplaceItemMutation = { __typename?: 'Mutation', replaceItem?: { __typename?: 'ObjectSchemaMutationResponse', code: string, message: string, success: boolean } | null };


export const CheckGlItemsDocument = gql`
    query checkGlItems($modelId: String!, $itemValues: [NodeValue]!, $glType: String!, $glNodeId: String) {
  glItems(
    modelId: $modelId
    itemValues: $itemValues
    glType: $glType
    glNodeId: $glNodeId
  ) {
    message
    success
    codes
  }
}
    `;

/**
 * __useCheckGlItemsQuery__
 *
 * To run a query within a React component, call `useCheckGlItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckGlItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckGlItemsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      itemValues: // value for 'itemValues'
 *      glType: // value for 'glType'
 *      glNodeId: // value for 'glNodeId'
 *   },
 * });
 */
export function useCheckGlItemsQuery(baseOptions: Apollo.QueryHookOptions<CheckGlItemsQuery, CheckGlItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckGlItemsQuery, CheckGlItemsQueryVariables>(CheckGlItemsDocument, options);
      }
export function useCheckGlItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckGlItemsQuery, CheckGlItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckGlItemsQuery, CheckGlItemsQueryVariables>(CheckGlItemsDocument, options);
        }
export type CheckGlItemsQueryHookResult = ReturnType<typeof useCheckGlItemsQuery>;
export type CheckGlItemsLazyQueryHookResult = ReturnType<typeof useCheckGlItemsLazyQuery>;
export type CheckGlItemsQueryResult = Apollo.QueryResult<CheckGlItemsQuery, CheckGlItemsQueryVariables>;
export const CheckInvalidGlsDocument = gql`
    query checkInvalidGls($modelId: String!, $priceListId: String!) {
  invalidGls(modelId: $modelId, priceListId: $priceListId) {
    id
    code
  }
}
    `;

/**
 * __useCheckInvalidGlsQuery__
 *
 * To run a query within a React component, call `useCheckInvalidGlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInvalidGlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInvalidGlsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      priceListId: // value for 'priceListId'
 *   },
 * });
 */
export function useCheckInvalidGlsQuery(baseOptions: Apollo.QueryHookOptions<CheckInvalidGlsQuery, CheckInvalidGlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckInvalidGlsQuery, CheckInvalidGlsQueryVariables>(CheckInvalidGlsDocument, options);
      }
export function useCheckInvalidGlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckInvalidGlsQuery, CheckInvalidGlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckInvalidGlsQuery, CheckInvalidGlsQueryVariables>(CheckInvalidGlsDocument, options);
        }
export type CheckInvalidGlsQueryHookResult = ReturnType<typeof useCheckInvalidGlsQuery>;
export type CheckInvalidGlsLazyQueryHookResult = ReturnType<typeof useCheckInvalidGlsLazyQuery>;
export type CheckInvalidGlsQueryResult = Apollo.QueryResult<CheckInvalidGlsQuery, CheckInvalidGlsQueryVariables>;
export const CheckGlMismatchDocument = gql`
    query CheckGlMismatch($priceListId: String!) {
  glMismatch(priceListId: $priceListId)
}
    `;

/**
 * __useCheckGlMismatchQuery__
 *
 * To run a query within a React component, call `useCheckGlMismatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckGlMismatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckGlMismatchQuery({
 *   variables: {
 *      priceListId: // value for 'priceListId'
 *   },
 * });
 */
export function useCheckGlMismatchQuery(baseOptions: Apollo.QueryHookOptions<CheckGlMismatchQuery, CheckGlMismatchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckGlMismatchQuery, CheckGlMismatchQueryVariables>(CheckGlMismatchDocument, options);
      }
export function useCheckGlMismatchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckGlMismatchQuery, CheckGlMismatchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckGlMismatchQuery, CheckGlMismatchQueryVariables>(CheckGlMismatchDocument, options);
        }
export type CheckGlMismatchQueryHookResult = ReturnType<typeof useCheckGlMismatchQuery>;
export type CheckGlMismatchLazyQueryHookResult = ReturnType<typeof useCheckGlMismatchLazyQuery>;
export type CheckGlMismatchQueryResult = Apollo.QueryResult<CheckGlMismatchQuery, CheckGlMismatchQueryVariables>;
export const PricesDocument = gql`
    query Prices($hierarchyId: String!, $contentTypeGroupCode: String!) {
  prices(hierarchyId: $hierarchyId, contentTypeGroupCode: $contentTypeGroupCode) {
    key
    value
  }
}
    `;

/**
 * __usePricesQuery__
 *
 * To run a query within a React component, call `usePricesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricesQuery({
 *   variables: {
 *      hierarchyId: // value for 'hierarchyId'
 *      contentTypeGroupCode: // value for 'contentTypeGroupCode'
 *   },
 * });
 */
export function usePricesQuery(baseOptions: Apollo.QueryHookOptions<PricesQuery, PricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PricesQuery, PricesQueryVariables>(PricesDocument, options);
      }
export function usePricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PricesQuery, PricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PricesQuery, PricesQueryVariables>(PricesDocument, options);
        }
export type PricesQueryHookResult = ReturnType<typeof usePricesQuery>;
export type PricesLazyQueryHookResult = ReturnType<typeof usePricesLazyQuery>;
export type PricesQueryResult = Apollo.QueryResult<PricesQuery, PricesQueryVariables>;
export const GetNodeIdByCodeDocument = gql`
    query GetNodeIdByCode($hierarchyId: String!, $nodeCode: String!) {
  getNodeIdByCode(hierarchyId: $hierarchyId, nodeCode: $nodeCode)
}
    `;

/**
 * __useGetNodeIdByCodeQuery__
 *
 * To run a query within a React component, call `useGetNodeIdByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeIdByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeIdByCodeQuery({
 *   variables: {
 *      hierarchyId: // value for 'hierarchyId'
 *      nodeCode: // value for 'nodeCode'
 *   },
 * });
 */
export function useGetNodeIdByCodeQuery(baseOptions: Apollo.QueryHookOptions<GetNodeIdByCodeQuery, GetNodeIdByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNodeIdByCodeQuery, GetNodeIdByCodeQueryVariables>(GetNodeIdByCodeDocument, options);
      }
export function useGetNodeIdByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNodeIdByCodeQuery, GetNodeIdByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNodeIdByCodeQuery, GetNodeIdByCodeQueryVariables>(GetNodeIdByCodeDocument, options);
        }
export type GetNodeIdByCodeQueryHookResult = ReturnType<typeof useGetNodeIdByCodeQuery>;
export type GetNodeIdByCodeLazyQueryHookResult = ReturnType<typeof useGetNodeIdByCodeLazyQuery>;
export type GetNodeIdByCodeQueryResult = Apollo.QueryResult<GetNodeIdByCodeQuery, GetNodeIdByCodeQueryVariables>;
export const CertificatePrototypeDocument = gql`
    mutation CertificatePrototype($prototypeId: String!, $cgtModelVariables: CgtModelVariables!) {
  certificatePrototype(
    prototypeId: $prototypeId
    cgtModelVariables: $cgtModelVariables
  ) {
    documentId
  }
}
    `;
export type CertificatePrototypeMutationFn = Apollo.MutationFunction<CertificatePrototypeMutation, CertificatePrototypeMutationVariables>;

/**
 * __useCertificatePrototypeMutation__
 *
 * To run a mutation, you first call `useCertificatePrototypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCertificatePrototypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [certificatePrototypeMutation, { data, loading, error }] = useCertificatePrototypeMutation({
 *   variables: {
 *      prototypeId: // value for 'prototypeId'
 *      cgtModelVariables: // value for 'cgtModelVariables'
 *   },
 * });
 */
export function useCertificatePrototypeMutation(baseOptions?: Apollo.MutationHookOptions<CertificatePrototypeMutation, CertificatePrototypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CertificatePrototypeMutation, CertificatePrototypeMutationVariables>(CertificatePrototypeDocument, options);
      }
export type CertificatePrototypeMutationHookResult = ReturnType<typeof useCertificatePrototypeMutation>;
export type CertificatePrototypeMutationResult = Apollo.MutationResult<CertificatePrototypeMutation>;
export type CertificatePrototypeMutationOptions = Apollo.BaseMutationOptions<CertificatePrototypeMutation, CertificatePrototypeMutationVariables>;
export const CertificateModelDocument = gql`
    mutation CertificateModel($aggregateId: String!, $nodeId: String!) {
  certificateModel(aggregateId: $aggregateId, nodeId: $nodeId) {
    documentId
    salesforceDocumentId
  }
}
    `;
export type CertificateModelMutationFn = Apollo.MutationFunction<CertificateModelMutation, CertificateModelMutationVariables>;

/**
 * __useCertificateModelMutation__
 *
 * To run a mutation, you first call `useCertificateModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCertificateModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [certificateModelMutation, { data, loading, error }] = useCertificateModelMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useCertificateModelMutation(baseOptions?: Apollo.MutationHookOptions<CertificateModelMutation, CertificateModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CertificateModelMutation, CertificateModelMutationVariables>(CertificateModelDocument, options);
      }
export type CertificateModelMutationHookResult = ReturnType<typeof useCertificateModelMutation>;
export type CertificateModelMutationResult = Apollo.MutationResult<CertificateModelMutation>;
export type CertificateModelMutationOptions = Apollo.BaseMutationOptions<CertificateModelMutation, CertificateModelMutationVariables>;
export const PublishModelDocument = gql`
    mutation PublishModel($aggregateId: String!, $nodeId: String!) {
  publishModel(aggregateId: $aggregateId, nodeId: $nodeId) {
    documentId
  }
}
    `;
export type PublishModelMutationFn = Apollo.MutationFunction<PublishModelMutation, PublishModelMutationVariables>;

/**
 * __usePublishModelMutation__
 *
 * To run a mutation, you first call `usePublishModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishModelMutation, { data, loading, error }] = usePublishModelMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function usePublishModelMutation(baseOptions?: Apollo.MutationHookOptions<PublishModelMutation, PublishModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishModelMutation, PublishModelMutationVariables>(PublishModelDocument, options);
      }
export type PublishModelMutationHookResult = ReturnType<typeof usePublishModelMutation>;
export type PublishModelMutationResult = Apollo.MutationResult<PublishModelMutation>;
export type PublishModelMutationOptions = Apollo.BaseMutationOptions<PublishModelMutation, PublishModelMutationVariables>;
export const AlignLongListsDocument = gql`
    mutation AlignLongLists($prototypeId: String!) {
  alignLongLists(prototypeId: $prototypeId) {
    code
    message
    success
  }
}
    `;
export type AlignLongListsMutationFn = Apollo.MutationFunction<AlignLongListsMutation, AlignLongListsMutationVariables>;

/**
 * __useAlignLongListsMutation__
 *
 * To run a mutation, you first call `useAlignLongListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlignLongListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alignLongListsMutation, { data, loading, error }] = useAlignLongListsMutation({
 *   variables: {
 *      prototypeId: // value for 'prototypeId'
 *   },
 * });
 */
export function useAlignLongListsMutation(baseOptions?: Apollo.MutationHookOptions<AlignLongListsMutation, AlignLongListsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AlignLongListsMutation, AlignLongListsMutationVariables>(AlignLongListsDocument, options);
      }
export type AlignLongListsMutationHookResult = ReturnType<typeof useAlignLongListsMutation>;
export type AlignLongListsMutationResult = Apollo.MutationResult<AlignLongListsMutation>;
export type AlignLongListsMutationOptions = Apollo.BaseMutationOptions<AlignLongListsMutation, AlignLongListsMutationVariables>;
export const AlignMismatchGlDocument = gql`
    mutation AlignMismatchGl($modelId: String!, $itemsCodes: [String!]!) {
  alignMismatchGl(modelId: $modelId, itemsCodes: $itemsCodes) {
    code
    message
    success
  }
}
    `;
export type AlignMismatchGlMutationFn = Apollo.MutationFunction<AlignMismatchGlMutation, AlignMismatchGlMutationVariables>;

/**
 * __useAlignMismatchGlMutation__
 *
 * To run a mutation, you first call `useAlignMismatchGlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlignMismatchGlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alignMismatchGlMutation, { data, loading, error }] = useAlignMismatchGlMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      itemsCodes: // value for 'itemsCodes'
 *   },
 * });
 */
export function useAlignMismatchGlMutation(baseOptions?: Apollo.MutationHookOptions<AlignMismatchGlMutation, AlignMismatchGlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AlignMismatchGlMutation, AlignMismatchGlMutationVariables>(AlignMismatchGlDocument, options);
      }
export type AlignMismatchGlMutationHookResult = ReturnType<typeof useAlignMismatchGlMutation>;
export type AlignMismatchGlMutationResult = Apollo.MutationResult<AlignMismatchGlMutation>;
export type AlignMismatchGlMutationOptions = Apollo.BaseMutationOptions<AlignMismatchGlMutation, AlignMismatchGlMutationVariables>;
export const AlignLonglistsSectionsDocument = gql`
    mutation AlignLonglistsSections($sectionNodeId: String!, $name: String!, $description: String!, $notes: String!) {
  alignLonglistsSections(
    sectionNodeId: $sectionNodeId
    name: $name
    description: $description
    notes: $notes
  ) {
    code
    message
    success
  }
}
    `;
export type AlignLonglistsSectionsMutationFn = Apollo.MutationFunction<AlignLonglistsSectionsMutation, AlignLonglistsSectionsMutationVariables>;

/**
 * __useAlignLonglistsSectionsMutation__
 *
 * To run a mutation, you first call `useAlignLonglistsSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlignLonglistsSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alignLonglistsSectionsMutation, { data, loading, error }] = useAlignLonglistsSectionsMutation({
 *   variables: {
 *      sectionNodeId: // value for 'sectionNodeId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useAlignLonglistsSectionsMutation(baseOptions?: Apollo.MutationHookOptions<AlignLonglistsSectionsMutation, AlignLonglistsSectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AlignLonglistsSectionsMutation, AlignLonglistsSectionsMutationVariables>(AlignLonglistsSectionsDocument, options);
      }
export type AlignLonglistsSectionsMutationHookResult = ReturnType<typeof useAlignLonglistsSectionsMutation>;
export type AlignLonglistsSectionsMutationResult = Apollo.MutationResult<AlignLonglistsSectionsMutation>;
export type AlignLonglistsSectionsMutationOptions = Apollo.BaseMutationOptions<AlignLonglistsSectionsMutation, AlignLonglistsSectionsMutationVariables>;
export const ReplaceItemDocument = gql`
    mutation ReplaceItem($nodeId: String!, $nodeCode: String!, $newItemCode: String!) {
  replaceItem(nodeId: $nodeId, nodeCode: $nodeCode, newItemCode: $newItemCode) {
    code
    message
    success
  }
}
    `;
export type ReplaceItemMutationFn = Apollo.MutationFunction<ReplaceItemMutation, ReplaceItemMutationVariables>;

/**
 * __useReplaceItemMutation__
 *
 * To run a mutation, you first call `useReplaceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceItemMutation, { data, loading, error }] = useReplaceItemMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      nodeCode: // value for 'nodeCode'
 *      newItemCode: // value for 'newItemCode'
 *   },
 * });
 */
export function useReplaceItemMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceItemMutation, ReplaceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceItemMutation, ReplaceItemMutationVariables>(ReplaceItemDocument, options);
      }
export type ReplaceItemMutationHookResult = ReturnType<typeof useReplaceItemMutation>;
export type ReplaceItemMutationResult = Apollo.MutationResult<ReplaceItemMutation>;
export type ReplaceItemMutationOptions = Apollo.BaseMutationOptions<ReplaceItemMutation, ReplaceItemMutationVariables>;