import React from 'react';

type Mode = 'create' | 'edit' | 'reset' | 'refresh';

type RefreshAction = {
    type: 'refresh';
};

type ResetAction = {
    type: 'reset';
};

type EditAction = {
    type: 'edit';
    payload: {
        hierarchyId: string;
        nodeId: string;
        linkFieldId: string;
        breadcrumb?: number;
        priceListValues?: string[];
        secondaryPriceListValues?: string[];
        startingHierarchyId?: string;
        startingNodeId?: string;
        sapProductHierarchyId?: string;
        priceListToUse?: string[];
    };
};

type CreateAction = {
    type: 'create';
    payload: {
        hierarchyId: string;
        nodeId: string;
        linkFieldId?: string;
        priceListValues?: string[];
        secondaryPriceListValues?: string[];
        startingHierarchyId?: string;
        startingNodeId?: string;
        sapProductHierarchyId?: string;
    };
};

type EditNodeDialogAction = RefreshAction | ResetAction | EditAction | CreateAction;

export type EditNodeDialogState = null | {
    mode: Mode;
    hierarchyId: string;
    nodeId: string;
    linkFieldId: string | null;
    priceListValues?: string[];
    secondaryPriceListValues?: string[];
    startingHierarchyId?: string;
    startingNodeId?: string;
    breadcrumb?: number;
    sapProductHierarchyId?: string;
    priceListToUse?: string[];
};

export const editNodeDialogReducer = (
    state: EditNodeDialogState,
    action: EditNodeDialogAction,
): EditNodeDialogState => {
    switch (action.type) {
        case 'reset':
            return null;

        case 'refresh':
            return state
                ? {
                      mode: 'refresh',
                      linkFieldId: state.linkFieldId,
                      hierarchyId: state.hierarchyId,
                      nodeId: state.nodeId,
                  }
                : null;

        case 'edit':
            return {
                mode: 'edit',
                hierarchyId: action.payload.hierarchyId,
                nodeId: action.payload.nodeId,
                linkFieldId: action.payload.linkFieldId,
                breadcrumb: action.payload.breadcrumb,
                priceListValues: action.payload.priceListValues ?? [],
                startingHierarchyId: action.payload.startingHierarchyId,
                startingNodeId: action.payload.startingNodeId,
                sapProductHierarchyId: action.payload.sapProductHierarchyId,
                priceListToUse: action.payload.priceListToUse,
            };

        case 'create':
            return {
                mode: 'create',
                hierarchyId: action.payload.hierarchyId,
                nodeId: action.payload.nodeId,
                linkFieldId: action.payload.linkFieldId ?? null,
                priceListValues: action.payload.priceListValues ?? [],
                secondaryPriceListValues: action.payload.secondaryPriceListValues ?? [],
                startingHierarchyId: action.payload.startingHierarchyId,
                startingNodeId: action.payload.startingNodeId,
                sapProductHierarchyId: action.payload.sapProductHierarchyId,
            };

        default:
            return state;
    }
};

export const EditNodeDialogContext = React.createContext<{
    state: EditNodeDialogState;
    dispatch: React.Dispatch<EditNodeDialogAction>;
}>({
    state: null,
    dispatch: () => null,
});
